import EfLink from '@elem/ui/ef-link';
import { useEffect, useRef } from 'react';
import IconClose from '../../icons/IconClose';
import './index.scss';

export default function WinCommon({
    title,
    desc,
    btnText,
    btnHref,
    okCallback,
    callback,
    btnDataLayer,
}: {
    title?: string;
    desc?: string;
    btnText?: string;
    btnHref?: string;
    okCallback?: any;
    callback: any;
    btnDataLayer?: any;
}) {
    const refDialog = useRef<any>(null);
    const closeHandle = () => {
        callback && callback();
    };
    const sureHandle = () => {
        okCallback && okCallback();
    };
    useEffect(() => {
        refDialog.current.showModal();
    }, []);

    return (
        <dialog className="ef-check-in-dialog ef-check-in-dialog-common" ref={refDialog}>
            <div className="ef-check-in-dialog-wrap">
                <div className="ef-check-in-dialog-body">
                    <div className="ef-check-in-dialog-title">{title}</div>
                    <button className="ef-check-in-dialog-close" onClick={closeHandle}>
                        <IconClose />
                    </button>
                    <div className="ef-check-in-dialog-desc">{desc}</div>
                    {!!btnText && (
                        <div className="ef-check-in-dialog-footer">
                            <EfLink className="ef-check-in-dialog-btn" onClick={sureHandle} href={btnHref} dataLayer={btnDataLayer}>
                                {btnText}
                            </EfLink>
                        </div>
                    )}
                </div>
            </div>
        </dialog>
    );
}
