'use client';

import { useCountDown } from 'ahooks';
import { useEffect, useState } from 'react';

export default function ItemCountdown({ data = {} }: { data: any }) {
    const {
        splitTextD,
        splitTextH,
        splitTextM,
        splitTextS,
        startTime,
        endTime,
        splitTextTheme,
        isUseCountdownStartText,
        countdownStartText,
        isUseCountdownEndText,
        countdownEndText,
    } = data;
    const systemTime = new Date().getTime();
    const startTime2 = startTime ? new Date(startTime).getTime() : 0;
    const endTime2 = endTime ? new Date(endTime).getTime() : 0;
    const isStartFlag = systemTime > startTime2; // 倒计时是否大于开始时间
    const targetTime = isStartFlag ? endTime2 : startTime2;

    const [isClient, setIsClient] = useState(false);
    const [, formattedRes] = useCountDown({
        targetDate: targetTime,
        //onEnd: () => endCallBack && endCallBack(),
    });
    const splitTextD2 = splitTextD || ':';
    const splitTextH2 = splitTextH || ':';
    const splitTextM2 = splitTextM || ':';
    const splitTextS2 = splitTextS || '';
    useEffect(() => {
        setIsClient(true);
    }, []);

    const { days, hours, minutes, seconds } = formattedRes;
    const hours2 = `00${hours}`.slice(-2);
    const minutes2 = `00${minutes}`.slice(-2);
    const seconds2 = `00${seconds}`.slice(-2);
    if (!isClient) {
        return null;
    }
    // 是否展示倒计时前的文案(距离开始,距离结束)
    const isShowStartTextFlag1 = isUseCountdownStartText && countdownStartText && !isStartFlag;
    const isShowStartTextFlag2 = isUseCountdownEndText && countdownEndText && isStartFlag;
    return (
        <div className={`glo-bar-countdown glo-bar-countdown-theme-${splitTextTheme}`}>
            {isShowStartTextFlag1 && <span className="glo-bar-countdown-item-start">{countdownStartText}</span>}
            {isShowStartTextFlag2 && <span className="glo-bar-countdown-item-start">{countdownEndText}</span>}
            {days > 0 && (
                <>
                    <span className="glo-bar-countdown-item1">{days}</span>
                    <span className="glo-bar-countdown-item2">{splitTextD2}</span>
                </>
            )}

            <>
                <span className="glo-bar-countdown-item1">{hours2}</span>
                <span className="glo-bar-countdown-item2">{splitTextH2}</span>
            </>

            <>
                <span className="glo-bar-countdown-item1">{minutes2}</span>
                <span className="glo-bar-countdown-item2">{splitTextM2}</span>
            </>

            <>
                <span className="glo-bar-countdown-item1">{seconds2}</span>
                <span className="glo-bar-countdown-item2">{splitTextS2}</span>
            </>
        </div>
    );
}
