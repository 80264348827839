export default function IconClose() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_1925_32331)">
                <circle cx="10" cy="10" r="10" fill="#919191" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4379 14.4379C14.2659 14.6098 14.1001 14.7388 13.9404 14.8248C13.7807 14.9108 13.6108 14.8637 13.4306 14.6835L10 11.2529L6.55711 14.6958C6.38513 14.8678 6.2193 14.9108 6.05961 14.8248C5.89991 14.7388 5.73408 14.6098 5.5621 14.4379C5.39012 14.2659 5.26114 14.1 5.17515 13.9403C5.08916 13.7807 5.13215 13.6148 5.30413 13.4428L8.74702 9.99995L5.31641 6.56935C5.13625 6.38918 5.08916 6.21925 5.17515 6.05956C5.26114 5.89986 5.39012 5.73403 5.5621 5.56205C5.73408 5.39007 5.89991 5.26109 6.05961 5.1751C6.2193 5.08911 6.38923 5.1362 6.5694 5.31637L10 8.74697L13.4306 5.31636C13.6026 5.14438 13.7705 5.09934 13.9343 5.18124C14.098 5.26314 14.2659 5.39007 14.4379 5.56205C14.6099 5.73403 14.7368 5.90191 14.8187 6.0657C14.9006 6.22949 14.8556 6.39737 14.6836 6.56935L11.253 9.99995L14.6836 13.4306C14.8638 13.6107 14.9108 13.7806 14.8249 13.9403C14.7389 14.1 14.6099 14.2659 14.4379 14.4379Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1925_32331">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
