'use client';

export default function DefaultStyle({ id, data = {} }: any) {
    const { prevNextColor = 'rgba(0, 0, 0, 0.10)' } = data;
    return (
        <>
            <style>
                {`
                    .temp-${id} .swiper .swiper-button-prev:after {
                        border-color: ${prevNextColor};
                    }
                    .temp-${id} .swiper .swiper-button-next:after {
                        border-color: ${prevNextColor};
                    }
                `}
            </style>
        </>
    );
}
