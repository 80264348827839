'use client';
import EfImg from '@elem/ui/ef-img';
import EditorLink from 'lowcode/components/EditorLink';
import ItemCountdown from './ItemCountdown';
import styles from './css/CompImgTextCountdown.module.scss';
export default function Content({ id, data = {}, index = 0 }: any) {
    const {
        link,
        title,
        // mobileTitle,
        desc,
        // mobileDesc,
        bgPc,
        bgH5,
        endTime,
        titleColor = '#fff',
        countdownStartTextColor = '#fff',
        descColor = '#fff',
        splitTextColor = '#fff',
        bgColor,
        isUseOnlyBg,
        textAlignPc = 'center',
        textAlignH5 = 'left',
    } = data;
    const styleA = {
        backgroundColor: isUseOnlyBg && bgColor ? bgColor : null,
        '--countdown-color-title': titleColor,
        '--countdown-color-label': countdownStartTextColor,
        '--countdown-color-desc': descColor,
        '--countdown-color-split': splitTextColor,
    } as any;

    return (
        <>
            <EditorLink
                className={`glo-bar-item glo-bar-item-${index} ${styles.CompImgTextCountdown} glo-bar-padding glo-bar-type-img-text-countdown${
                    isUseOnlyBg ? ' glo-bar-only-bgcolor' : ' glo-bar-only-bgimg'
                }`}
                href={link}
                style={styleA}
            >
                {!isUseOnlyBg && <EfImg pc={bgPc} h5={bgH5} className="glo-bar-img" />}
                <div className={`glo-bar-text-box glo-bar-text-box-pc-${textAlignPc} glo-bar-text-box-h5-${textAlignH5}`}>
                    <div className="glo-bar-text-box-l">
                        {title && <div className="glo-bar-title">{title}</div>}
                        {desc && <div className="glo-bar-desc">{desc}</div>}
                    </div>
                    {/* <div className="glo-bar-text-box-l mobile">
                        {mobileTitle && <div className="glo-bar-title mobile">{mobileTitle}</div>}
                        {mobileDesc && <div className="glo-bar-desc mobile">{mobileDesc}</div>}
                    </div> */}
                    {endTime && (
                        <div className="glo-bar-text-box-r-down">
                            <ItemCountdown data={data} />
                        </div>
                    )}
                </div>
            </EditorLink>
        </>
    );
}
