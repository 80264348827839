'use client';
import classNames from 'classnames';
import './index.scss';

export interface EfImgProps {
    /**
     * 所有分辨率展示的图片链接
     */
    pc: string;
    /**
     * 分辨率[1024, 768]
     */
    pad?: string;
    /**
     * 分辨率[1024, 0) 或 (768, 0)
     */
    h5?: string;
    /**
     * 介绍这是个什么图片
     */
    alt?: string;
    /**
     * 样式名
     */
    className?: string;
    /**
     * 高度
     */
    height?: number;
}

/**
 * 维护:谢保林
 *
 * 图片容器
 *
 * 为提升性能,使用 background 来展示,因此使用时必须给这个容器设置高度,宽度默认为100%
 *
 * 其中只展示图片中间部分,即 background-size: cover;
 */
export default function EfImg({ className = '', alt = 'EF-IMG', pc, pad, h5, height = 0 }: EfImgProps) {
    const prefixCls = 'ef-img';
    const compCls = classNames(prefixCls, {
        [`${className}`]: className,
        [`${prefixCls}-has-pad`]: pad,
    });
    return (
        <div className={compCls} data-alt={alt || null}>
            {h5 && (
                <figure
                    className={`${prefixCls}-item ${prefixCls}-item-h5`}
                    style={{
                        backgroundImage: `url('${h5}')`,
                        ...(height && { height }),
                    }}
                ></figure>
            )}
            {pad && (
                <figure
                    className={`${prefixCls}-item ${prefixCls}-item-pad`}
                    style={{
                        backgroundImage: `url('${pad}')`,
                        ...(height && { height }),
                    }}
                ></figure>
            )}
            <figure
                className={`${prefixCls}-item ${prefixCls}-item-pc`}
                style={{
                    backgroundImage: `url('${pc}')`,
                    ...(height && { height }),
                }}
            ></figure>
        </div>
    );
}
