'use client';
import EditorLink from 'lowcode/components/EditorLink';

export default function Content({ id, data = {}, index = 0 }: any) {
    const { link, title, desc, btnType, btnText, bgColor, titleColor, btnColor } = data;

    return (
        <>
            <EditorLink
                className={`glo-bar-item glo-bar-item-${index} glo-bar-padding glo-bar-type-img-text-btn glo-bar-type-img-text-btn-${btnType}`}
                href={link}
                style={{
                    background: bgColor || null,
                }}
            >
                <div className="glo-bar-text-box">
                    <div className="glo-bar-text-box-l">
                        {title && (
                            <div
                                className="glo-bar-title"
                                style={{
                                    color: titleColor || null,
                                }}
                            >
                                {title}
                            </div>
                        )}
                        {desc && (
                            <div
                                className="glo-bar-desc"
                                style={{
                                    color: titleColor || null,
                                }}
                            >
                                {desc}
                            </div>
                        )}
                    </div>
                    {btnText && (
                        <div className="glo-bar-text-box-r">
                            <div
                                className="glo-bar-btn"
                                style={{
                                    color: btnColor || null,
                                }}
                            >
                                {btnText}
                            </div>
                        </div>
                    )}
                </div>
            </EditorLink>
        </>
    );
}
