'use client';
import ItemComp from './ItemComp';

export default function Content({ id, data = {} }: any) {
    const { list, showCloseBtn, closeBtnColor } = data;
    const itemData = list[0] || {};
    // 关闭
    const closeHandle = () => {
        const elem = document.querySelector(`.temp-${id}`);
        if (elem) {
            elem.classList.add('editor-glo-bar-hide');
        }
    };
    return (
        <>
            <ItemComp id={id} data={itemData} index={0} />
            {showCloseBtn && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    className="editor-glo-bar-close"
                    onClick={closeHandle}
                    style={{
                        color: closeBtnColor || 'black',
                    }}
                >
                    <path d="M7.75736 16.7427L16.2426 8.25743M16.2426 16.7427L7.75736 8.25743" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
            )}
        </>
    );
}
