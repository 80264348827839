'use client';
import EditorLink from 'lowcode/components/EditorLink';

export default function Content({ id, data = {}, index = 0 }: any) {
    const { link, title, desc, titleColor, bgColor } = data;

    return (
        <>
            <EditorLink
                className={`glo-bar-item glo-bar-item-${index} glo-bar-padding glo-bar-type-text`}
                href={link}
                style={{
                    background: bgColor || null,
                }}
            >
                <div className="glo-bar-text-box">
                    {title && (
                        <div
                            className="glo-bar-title"
                            style={{
                                color: titleColor || null,
                            }}
                        >
                            {title}
                        </div>
                    )}
                    {desc && (
                        <div
                            className="glo-bar-desc"
                            style={{
                                color: titleColor || null,
                            }}
                        >
                            {desc}
                        </div>
                    )}
                </div>
            </EditorLink>
        </>
    );
}
