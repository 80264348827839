'use client';
import CompImg from './CompImg';
import CompText from './CompText';
import CompImgText from './CompImgText';
import CompImgTextBtn from './CompImgTextBtn';
import CompImgTextCountdown from './CompImgTextCountdown';

export default function Content({ id, data = {}, index = 0 }: any) {
    const { barType = '' } = data;

    return (
        <>
            {barType === 'img' && <CompImg id={id} data={data} />}
            {barType === 'text' && <CompText id={id} data={data} />}
            {barType === 'img_text' && <CompImgText id={id} data={data} />}
            {barType === 'img_text_btn' && <CompImgTextBtn id={id} data={data} />}
            {barType === 'img_text_countdown' && <CompImgTextCountdown id={id} data={data} />}
        </>
    );
}
