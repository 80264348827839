import { loginUrl } from '@/service/util';
import request from '@/utils/request';
// 打卡-是否在白名单内
export const getBlacklist = async (locale: string) => {
    return request(`${loginUrl(locale)}/integral/web/riskBlacklistInfo/getBlacklist`, {
        needToken: true,
        method: 'get',
    });
};

/**
 * @description 获取站点打卡活动信息-不需要登录
 * @description 接口不能额外加时间转换的参数, 后端接口返回的都是对应时区的当地时间 compaignStartTime, compaignEndTime, localCurrentTime
 */
export const rewardSignInCampaignGet = async (locale: string, pathname: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardSignInCampaign/get?campaignUrl=${pathname}`, {
        method: 'get',
    });
};

/**
 * @description 获取站点打卡活动信息-需要登录
 * @description 接口不能额外加时间转换的参数, 后端接口返回的都是对应时区的当地时间 compaignStartTime, compaignEndTime, localCurrentTime
 */
export const rewardSignInCampaignGetLogin = async (locale: string, pathname: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardSignInCampaign/getLoginCampaign?campaignUrl=${pathname}`, {
        method: 'get',
        needToken: true,
    });
};

// 获取登录用户打卡情况
export const getSignInRecord = async (locale: string, signInCampaignId: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardSignInCampaign/getSignInRecord/${signInCampaignId}`, {
        method: 'get',
        needToken: true,
    });
};

// 获取用户打卡活动积分
export const getSignInIntegral = async (locale: string, signInCampaignId: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardSignInCampaign/getSignInIntegral/${signInCampaignId}`, {
        method: 'get',
        needToken: true,
    });
};

// 打卡
export const rewardSignInCampaignSignIn = async (locale: string, data: any) => {
    return request(`${loginUrl(locale)}/integral/web/rewardSignInCampaign/signIn`, {
        method: 'post',
        data: data,
        needToken: true,
    });
};

// 判断登录用户能否新增补卡次数
export const checkUserGainReissue = async (locale: string, signInCampaignId: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardSignInCampaign/checkUserGainReissue/${signInCampaignId}`, {
        method: 'get',
        needToken: true,
    });
};

// 获取打卡次数
export const gainReissueCount = async (locale: string, data: any) => {
    return request(
        `${loginUrl(locale)}/integral/web/rewardSignInCampaign/gainReissueCount
`,
        {
            method: 'post',
            data: data,
            needToken: true,
        },
    );
};

// 优惠卷列表查询
export const listCoupon = async (locale: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardRedeemCoupon/listCoupon?redeemSkip=COUPONS&recordSource=11`, {
        method: 'get',
        needToken: true,
    });
};

// 优惠卷列表查询
export const autoCouponListWithGiftCard = async (locale: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardRedeemRule/list`, {
        method: 'post',
    });
};

// 用户积分
export const rewardUserInfoSelectByUserId = async (locale: string) => {
    return request(`${loginUrl(locale)}/integral/web/rewardUserInfo/selectByUserId`, {
        method: 'get',
        needToken: true,
    });
};
