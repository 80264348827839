import gtm from '@/utils/gtm';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './index.scss';

interface EfBundlesCalculatorProps {
    /**
     * 默认购买数量
     */
    defaultQuantity?: number;
    /**
     * 当前操作的 item
     */
    item: any;
    /**
     * 数量变化回周
     */
    callback?: any;
    /**
     * 样式名
     */
    className?: string;
}

/**
 * 维护:谢保林
 *
 * 价格计算器
 */
const EfBundlesCalculator: React.FC<EfBundlesCalculatorProps> = ({ defaultQuantity = 0, item = {}, callback, className }) => {
    const prefixCls = `ef-bundles-calculator`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
    });
    const [quantity, setQuantity] = useState<number>(defaultQuantity || 0); //购买数量
    const inventoryQuantity = item?.inventoryQuantity || 0; //库存
    const maxCanBuyCount = Number(`${item.maxCanBuyCount}`); //最多能购买

    // 输入框输入值
    const onChangeHandle = (e: any) => {
        const value2 = parseInt(e.target.value) || 0;
        let value3 = Math.min(value2, inventoryQuantity); //输入的数量不能大于库存
        // 如果有最大购买量
        if (maxCanBuyCount && typeof maxCanBuyCount === 'number') {
            value3 = Math.min(value3, maxCanBuyCount);
        }
        setQuantity(value3);
        callback && callback(value3, item);
    };
    // -1
    const redHandle = () => {
        const value2: number = quantity || 0;
        if (value2 && inventoryQuantity) {
            const value3 = value2 - 1;
            setQuantity(value3);
            callback && callback(value3, item);
            try {
                gtm.push({
                    event: 'uaEvent',
                    event_name: 'bundle_remove_from_cart',
                    product_name: item?.name,
                    price: item?.discountPrice,
                    quantity: value3,
                    button_name: 'remove',
                    list_name: 'none',
                    tag: 'none',
                });
            } catch (e) {
                console.error(e);
            }
        }
    };
    // +1
    const addHandle = () => {
        let value2 = (quantity || 0) + 1;
        if (inventoryQuantity && value2 <= inventoryQuantity) {
            // 如果有最大购买量
            if (maxCanBuyCount && typeof maxCanBuyCount === 'number') {
                value2 = Math.min(value2, maxCanBuyCount);
            }
            setQuantity(value2);
            callback && callback(value2, item);
            try {
                gtm.push({
                    event: 'uaEvent',
                    event_name: 'bundle_add_to_cart',
                    product_name: item?.name,
                    price: item?.discountPrice,
                    quantity: value2,
                    button_name: 'add',
                    list_name: 'none',
                    tag: 'none',
                });
            } catch (e) {
                console.error(e);
            }
        }
    };
    useEffect(() => {
        setQuantity(defaultQuantity);
    }, [defaultQuantity]);
    return (
        <div className={classes}>
            <span className={`${prefixCls}-red${quantity <= 0 ? ' ef-bundles-calculator-disabled' : ''}`} onClick={redHandle}>
                <span className={`${prefixCls}-icon`}></span>
            </span>
            <input className={`${prefixCls}-num`} value={quantity} onChange={onChangeHandle} />
            <span className={`${prefixCls}-add${quantity >= inventoryQuantity ? ' ef-bundles-calculator-disabled' : ''}`} onClick={addHandle}>
                <span className={`${prefixCls}-icon`}></span>
            </span>
        </div>
    );
};

export default EfBundlesCalculator;
