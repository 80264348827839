'use client';
import EditorLink from 'lowcode/components/EditorLink';
import EditorImg from 'lowcode/components/EditorImg';

export default function Content({ id, data = {}, index = 0 }: any) {
    const { link, bgPc, bgH5 } = data;

    return (
        <>
            <EditorLink className={`glo-bar-item glo-bar-item-${index} glo-bar-type-text`} href={link}>
                <EditorImg pc={bgPc} h5={bgH5} />
            </EditorLink>
        </>
    );
}
