'use client';
import EditorLink from 'lowcode/components/EditorLink';
import EditorImg from 'lowcode/components/EditorImg';

export default function Content({ id, data = {}, index = 0 }: any) {
    const { link, title, desc, bgPc, bgH5, titleColor, bgColor, isUseOnlyBg } = data;

    return (
        <>
            <EditorLink
                className={`glo-bar-item glo-bar-item-${index} glo-bar-padding glo-bar-type-img-text${
                    isUseOnlyBg ? ' glo-bar-only-bgcolor' : ' glo-bar-only-bgimg'
                }`}
                href={link}
                style={{
                    backgroundColor: isUseOnlyBg && bgColor ? bgColor : null,
                }}
            >
                {!isUseOnlyBg && <EditorImg pc={bgPc} h5={bgH5} className="glo-bar-img" />}
                <div className="glo-bar-text-box">
                    {title && (
                        <div
                            className="glo-bar-title"
                            style={{
                                color: titleColor || null,
                            }}
                        >
                            {title}
                        </div>
                    )}
                    {desc && (
                        <div
                            className="glo-bar-desc"
                            style={{
                                color: titleColor || null,
                            }}
                        >
                            {desc}
                        </div>
                    )}
                </div>
            </EditorLink>
        </>
    );
}
