export const textLangCommon: any = {
    jp: {
        title: '再チェックイン',
        btn1: 'OK',
        visitPageDesc: '閲覧が完了しました。再チェックインの機会を獲得できました。',
        visitPageBack: '戻る',
    },
    us: {
        title: 'Make up for check-in',
        btn1: 'OK',
        visitPageDesc: 'Browsing completed. Congratulations on getting a make-up check-in card.',
        visitPageBack: 'Back',
    },
    ca: {
        title: 'Make up for check-in',
        btn1: 'OK',
        visitPageDesc: 'Browsing completed. Congratulations on getting a make-up check-in card.',
        visitPageBack: 'Back',
    },
    au: {
        title: 'Make up for check-in',
        btn1: 'OK',
        visitPageDesc: 'Browsing completed. Congratulations on getting a make-up check-in card.',
        visitPageBack: 'Back',
    },
    eu: {
        title: 'Make up for check-in',
        btn1: 'OK',
        visitPageDesc: 'Browsing completed. Congratulations on getting a make-up check-in card.',
        visitPageBack: 'Back',
    },
    uk: {
        title: 'Make up for check-in',
        btn1: 'OK',
        visitPageDesc: 'Browsing completed. Congratulations on getting a make-up check-in card.',
        visitPageBack: 'Back',
    },
    de: {
        title: 'Check-in nachholen',
        btn1: 'OK',
        visitPageDesc: 'Browsen beendet Wir gratulieren zum Erhalt einer Wiederherstellungs-Check-in-Karte.',
        visitPageBack: 'Zurück',
    },
    es: {
        title: 'Recuperar visitas',
        btn1: 'Aceptar',
        visitPageDesc: 'Navegación completada. Enhorabuena por haber conseguido una tarjeta de recuperación de visitas.',
        visitPageBack: 'Atrás',
    },
    fr: {
        title: 'Rattraper une présence manquée',
        btn1: 'OK',
        visitPageDesc: 'Navigation terminée. Félicitations ! Vous avez obtenu une nouvelle chance de confirmer votre présence manquée.',
        visitPageBack: 'Retour',
    },
    it: {
        title: 'Recupera un check-in',
        btn1: 'OK',
        visitPageDesc: 'Navigazione completata. Congratulazioni: hai ottenuto una scheda di recupero check-in.',
        visitPageBack: 'Indietro',
    },
    kr: {
        title: '체크인 보충',
        btn1: '확인',
        visitPageDesc: '검색이 완료되었습니다. 체크인 보충 카드 획득을 축하합니다.',
        visitPageBack: '뒤로 가기',
    },
    za: {
        title: 'Make up for check-in',
        btn1: 'OK',
        visitPageDesc: 'Browsing completed. Congratulations on getting a make-up check-in card.',
        visitPageBack: 'Back',
    },
};
